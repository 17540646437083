<template>
  <div class="container">
    <div class="header card">
      <h1>
        <span v-lang="'persons.title_details'"></span>
      </h1>
      <div class="actions">
        <a class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></a>
      </div>
    </div>
    <Loader2 v-if="!resultsLoaded" />

    <div class="card" v-else>
      <table>
        <tr>
          <td v-lang="'persons.number'"></td>
          <td>{{ model.number }}</td>
        </tr>
        <tr>
          <td v-lang="'persons.institution'"></td>
          <td>{{ model.institution }}</td>
        </tr>
        <tr>
          <td v-lang="'persons.registration_date'"></td>
          <td>{{ $moment(model.registrationDate).format('DD.MM.YYYY.') }}</td>
        </tr>
        <tr>
          <td colspan="2" style="font-weight:bold;" v-lang="'persons.person_data'"></td>
        </tr>
        <tr>
          <td v-lang="'persons.gender'"></td>
          <td>{{ model.gender }}</td>
        </tr>
        <tr>
          <td v-lang="'persons.years'"></td>
          <td>{{ model.years }}</td>
        </tr>
        <tr>
          <td v-lang="'persons.citizenship'"></td>
          <td>{{ model.citizenship}}</td>
        </tr>
      </table>
      <fieldset class="fieldset">
        <legend v-lang="'victims.exploitation_mode'"></legend>
        <div class="fieldset-container">
          <div
            v-for="(list, key) in codesModel.exploitationModeCodes"
            class="input-checkbox"
            :key="key"
          >
            <label :for="list.pkCodeId">
              <input
                disabled
                class="m-0"
                type="checkbox"
                v-model="model.exploitationModes"
                autocomplete="off"
                :value="list.pkCodeId"
                :id="list.pkCodeId"
                hidden
              />
              <span v-html="list.name"></span>
            </label>
          </div>
        </div>
      </fieldset>
      <fieldset class="fieldset">
        <div class="fieldset-container">
          <div class="input-checkbox">
            <label for="isReportedForFelony">
              <input
                disabled
                class="m-0"
                type="checkbox"
                v-model="model.isReportedForFelony"
                id="isReportedForFelony"
                autocomplete="off"
                hidden
              />
              <span v-lang="'persons.reported_KD'"></span>
            </label>
          </div>
          <div class="input-checkbox">
            <label for="isIndictment">
              <input
                disabled
                class="m-0"
                type="checkbox"
                v-model="model.isIndictment"
                id="isIndictment"
                autocomplete="off"
                hidden
              />
              <span v-lang="'persons.indictment'"></span>
            </label>
          </div>
          <div class="input-checkbox">
            <label for="isFelonyQualifiedAsHumanTrafficking">
              <input
                disabled
                class="m-0"
                type="checkbox"
                v-model="model.isFelonyQualifiedAsHumanTrafficking"
                id="isFelonyQualifiedAsHumanTrafficking"
                autocomplete="off"
                hidden
              />
              <span v-lang="'persons.qualified_as_human_trafficking'"></span>
            </label>
          </div>
          <div class="input-checkbox">
            <label for="finalCourtJudgment">
              <input
                disabled
                class="m-0"
                type="checkbox"
                v-model="model.finalCourtJudgment"
                id="finalCourtJudgment"
                autocomplete="off"
                hidden
              />
              <span v-lang="'persons.final_decision_of_the_institution'"></span>
            </label>
          </div>
          <div class="input-checkbox">
            <label for="courtJudgment">
              <input
                disabled
                class="m-0"
                type="checkbox"
                v-model="model.courtJudgment"
                id="courtJudgment"
                autocomplete="off"
                hidden
              />
              <span v-lang="'persons.judgment'"></span>
            </label>
          </div>
          <div></div>
          <div class="input-checkbox spec-checkbox">
            <label for="fundsFreezing">
              <input
                disabled
                class="m-0"
                type="checkbox"
                v-model="model.fundsFreezing"
                id="fundsFreezing"
                autocomplete="off"
                hidden
              />
              <span v-lang="'persons.freezing_of_funds'"></span>
            </label>
            <div v-if="model.fundsFreezing" class="input m-0 custom-input">
              <label for="estimatedValueOfFreezedProperty">
                <span v-lang="'persons.estimated_value_of_assets'"></span>
              </label>
              <input
                disabled
                class="m-0"
                v-model="model.estimatedValueOfFreezedProperty "
                type="text"
                name="estimatedValueOfFreezedProperty "
                autocomplete="off"
                oninput="this.value = this.value.replace(/[^0-9\.]+/, '');"
              />
            </div>
          </div>
          <div class="input-checkbox spec-checkbox">
            <label for="confiscatedFunds">
              <input
                disabled
                class="m-0"
                type="checkbox"
                v-model="model.confiscatedFunds"
                id="confiscatedFunds"
                autocomplete="off"
                hidden
              />
              <span v-lang="'persons.confiscatedFunds'"></span>
            </label>
            <div v-if="model.confiscatedFunds" class="input m-0 custom-input">
              <label for="estimatedValueOfConfiscatedProperty">
                <span v-lang="'persons.estimatedValueOfConfiscatedProperty'"></span>
              </label>
              <input
                disabled
                class="m-0"
                v-model="model.estimatedValueOfConfiscatedProperty "
                type="text"
                name="estimatedValueOfConfiscatedProperty "
                autocomplete="off"
                oninput="this.value = this.value.replace(/[^0-9\.]+/, '');"
              />
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="fieldset">
        <legend v-lang="'persons.prosecution_decision'"></legend>
        <div class="fieldset-container">
          <div
            v-for="(list, key) in codesModel.prosecutionDecisionModes"
            class="input-checkbox"
            :key="key"
          >
            <label :for="list.pkCodeId">
              <input
                disabled
                class="m-0"
                type="checkbox"
                v-model="model.prosecutionDecisionModes"
                :id="list.pkCodeId"
                :value="list.pkCodeId"
                autocomplete="off"
                hidden
              />
              <span v-html="list.name"></span>
            </label>
          </div>
          <div
            class="input m-0 custom-input"
            v-if="
                    !$_.isEmpty(model.recruitingModes) &&
                      model.recruitingModes.includes(1473)
                  "
          >
            <label for="otherRecruitmentMode">
              <span v-lang="'victims.other_recruitment_mode'"></span>
            </label>
            <input
              disabled
              class="m-0"
              v-model="model.otherRecruitmentMode"
              type="text"
              name="otherRecruitmentMode"
              autocomplete="off"
            />
          </div>
        </div>
      </fieldset>
      <fieldset class="fieldset">
        <legend v-lang="'persons.court_decision'"></legend>
        <div class="fieldset-container">
          <div
            v-for="(list, key) in codesModel.courtJudgmentModes"
            class="input-checkbox"
            :key="key"
          >
            <label :for="list.pkCodeId">
              <input
                disabled
                class="m-0"
                type="checkbox"
                v-model="model.courtJudgmentModes"
                autocomplete="off"
                :value="list.pkCodeId"
                :id="list.pkCodeId"
                hidden
              />
              <span v-html="list.name"></span>
            </label>
          </div>
          <div v-if="model.courtJudgmentModes.includes(2797)" class="input m-0 custom-input">
            <label for="otherCourtJudgment">
              <span v-lang="'persons.court_decision'"></span>
            </label>
            <input
              disabled
              class="m-0"
              v-model="model.otherCourtJudgment"
              type="text"
              name="otherCourtJudgment"
              autocomplete="off"
            />
          </div>
        </div>
      </fieldset>
      <fieldset class="fieldset">
        <legend v-lang="'persons.criminal_offense'"></legend>
        <div class="fieldset-container">
          <div v-for="(list, key) in codesModel.felonies" class="input-checkbox" :key="key">
            <label :for="list.pkCodeId">
              <input
                disabled
                class="m-0"
                type="checkbox"
                v-model="model.felonies"
                autocomplete="off"
                :value="list.pkCodeId"
                :id="list.pkCodeId"
                hidden
              />
              <span v-html="list.name"></span>
            </label>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Loader2 from "@/components/General/Loader2";
import store from "./store";
import moment from "moment";
export default {
  components: {
    Loader2
  },
  data() {
    return {
      resultsLoaded: false,
      model: null,
      codesModel: null
    };
  },
  created() {
    this.getById();
  },
  methods: {
    getById() {
      var $this = this;
      store
        .dispatch("GET_PERSON_BY_ID", this.$route.params.id)
        .then(response => {
          $this.model = response.data.model;
          this.getCodes();
        })
        .catch(error => {
          if (error.status == 400) $this.errorModel = error.data;
        });
    },
    getCodes() {
      var $this = this;
      store
        .dispatch("GET_CODES")
        .then(response => {
          this.codesModel = response.data.model;
          $this.resultsLoaded = true;
        })
        .catch(error => {});
    }
  }
};
</script>

<style lang="scss" scoped>
tr {
  :first-child {
    width: 25%;
  }
}
td:nth-child(2) {
  font-weight: bold;
}
div.input-checkbox {
  color: black;
  display: -webkit-inline-flex;
  margin: 0px 25px;
  margin-top: 5px;

  label {
    margin-bottom: 10px !important;
  }
}
.fieldset {
  padding-top: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  grid-column: 1/4;
  .fieldset-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
}
.spec-checkbox {
  display: flex !important;
  flex-direction: column;
}
</style>